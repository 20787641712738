<template>
  <div class="max-w-6xl mx-auto overflow-auto" v-fade-in="!loading">
    <div class="text-2xl font-bold text-red-500 p-4 text-center">
      Course Content
    </div>

    <div class="flex flex-col justify-center items-center font-semibold">

      <div class="text-base">{{  program.display_title }}  </div>
      <div v-if="program.admitted" class="text-purple-500">Group : {{ ((program.admitted || {}).program_group || {}).name }}</div>
      <div> Session : {{ (program.batch||{}).year }} </div>

      <div> Course : {{ ((program.batch || {}).course || {}).name }}</div>

      <div> Service Point : {{ (program.service_point || {}).name}}</div>
      </div>

    <div class="flex justify-between items-center mb-2">
      <div class="flex justify-center">

          <btn v-if="program.admitted" variant="info" :router-path="{name:'CourseSchedule', params:{ programId:$route.params.programId }}"  >
            Schedules
          </btn>
          <btn v-else variant="info" :router-path="`/courses/${$route.params.programId}/${$route.params.courseName}/${$route.params.batchName}/enrollment`" >
              Enroll
          </btn>
          
      </div>
      <div class="flex justify-end">
        <div class="flex gap-2">
            <label class="flex items-center">
              <input type="radio" v-model="candidate_type" class="w-4 h-4" value="1" checked />
              <span class="ml-2 text-base">1st Timer</span>
            </label>
            <label class="flex items-center ml-2">
              <input type="radio" v-model="candidate_type" class="w-4 h-4" value="2" />
              <span class="ml-2 text-base">2nd Timer</span>
            </label>
          </div>
      </div>
    </div>
   <table class="border w-full">
    <thead class="border">
      <th class="py-3 px-6 border-r">Lecture</th>
      <th class="py-3 px-6"> Topics </th>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id" class="border">
        <th class="py-3 px-6 border-r">{{ item.title }}</th>
        <td class="py-3 px-6">
          <span v-html=" item.topics "></span>
        </td>
      </tr>
    </tbody>
   </table>
  </div>
</template>

<script>
export default{

  data(){
    return {
      loading: false,
      items:[],
      candidate_type: 1,
      program:{},
    }
  },

  
  created() {
    this.loadItems();
  },

  methods:{
    loadItems() {
      this.loaded = true;

      this.$store
          .dispatch('public/courseContent', {
            params: {
              program_id : this.$route.params.programId,
              candidate_type: this.candidate_type,
              per_page: 20
            },
            success: (response) => {
              this.items = response.data.session_topics;
              this.program = response.data.program
            },
            error: () => {},
          })
          .finally(() => {
            this.loaded = false;
          });
    },
  },

  watch:{
    candidate_type(data){
      if(data){
        this.loadItems();
      }
    }
  }

}
</script>